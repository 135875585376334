import moment, { Duration, Moment } from "moment";
import { FORMATS } from "./formats";

export const CONVERSIONS = {
  unixToDateTimeString: (value: number, seconds = false) : string =>
    moment(value).format(seconds ? FORMATS.dateHMS : FORMATS.dateHM),
  dateTimeStringToUnix: (value: string) : number =>
    moment(value, FORMATS.dateHM).unix() * 1000,
  durationToHMS: (value: Duration) : string => {
    const days = value.get("days");
    const hours = value.get("hours");
    const minutes = value.get("minutes");
    const seconds = value.get("seconds");

    const pad = (num: number, size: number): string => {
      let s = num + "";

      while (s.length < size) {
        s = "0" + s;
      }

      return s;
    };

    const timePart = `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
    if (days == 0) return timePart;

    return `${days}d ${timePart}`;
  },
  momentToMonthHuman: (value: Moment): string =>
    value.format(FORMATS.monthHuman),
  momentToDateHuman: (value: Moment): string => value.format(FORMATS.dateHuman),
  momentToDateHM: (value: Moment | null): string =>
    value ? value.format(FORMATS.dateHM) : "0000-00-00 00:00",
  momentToDateHMS: (value: Moment | null): string =>
    value ? value.format(FORMATS.dateHMS) : "0000-00-00 00:00:00",
  dateHMToMoment: (value: string): Moment => moment(value, FORMATS.dateHM),
  dateHMSToMoment: (value: string): Moment => moment(value, FORMATS.dateHMS),
};
