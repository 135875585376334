import api from "@/services/api";

const url = '/ProductionOrders'

class OrderService {
    async getDashboardData(machineId: string) {
        const response = await api().get(`${url}/${machineId}/dashboard-data`)
        // return response.data === "" ? null : response.data
        return response.data || null;
    }
}

export const orderService = new OrderService();