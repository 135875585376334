import * as d3 from 'd3';
import { IntervalChartDto, IntervalDto } from '../models/chart-dto';
import moment from "moment";
import { OrderDashboardDto } from '@/modules/orders/models/order-dto';

export class IntervalChart {
    protected _svg: d3.Selection<SVGElement, null, HTMLElement, null>;
    protected _resizeObserver!: ResizeObserver;
    private _initDone = false;

    protected _x!: d3.ScaleTime<number, number, never>;
    protected _y!: d3.ScaleLinear<number, number, never>;

    protected _margin = { l: 16, r: 24, t: 24, b: 34, axes: 4 };
    protected _layout = {
        w: 0, h: 0,
        wChart: 0, hChart: 0,
        paddingBars: 0.05
    };

    private _intervalData: IntervalChartDto = { intervalBars: [], intervalMinutes: 15, shiftEnd: new Date(), shiftStart: new Date() };
    private _orderData: OrderDashboardDto | null = null;

    constructor(svgId: string) {
        this._svg = d3.select<SVGElement, null>(`svg#${svgId}`);
        this.init();
    }

    public setIntervalData(data: IntervalChartDto | null): IntervalChart {
        if (!this._initDone) return this;

        if (data == null) {
            this._intervalData = { intervalBars: [], intervalMinutes: 15, shiftEnd: new Date(), shiftStart: new Date() };
        }
        else
            this._intervalData = data;


        const xExtend = [moment(this._intervalData.shiftStart).valueOf(), moment(this._intervalData.shiftEnd).valueOf()] as [number, number]
        const yExtent = [d3.max(this._intervalData.intervalBars, d => d.nominalProduction), 0] as [number, number]

        // axes domains
        this._x.domain(xExtend);
        this._y.domain(yExtent);

        return this.resize();
    }

    public setOrderData(data: OrderDashboardDto | null): IntervalChart {
        if (!this._initDone) return this;

        this._orderData = data;

        return this.resize();
    }

    private init() {
        if (this._initDone) return this;

        this._resizeObserver = new ResizeObserver(() => this.resize());
        this._resizeObserver.observe(this._svg.node()!.parentElement as Element); // eslint-disable-line

        // axes
        this._x = d3.scaleTime();
        this._y = d3.scaleLinear();

        this._initDone = true;
        return this.resize();
    }

    private resize() {
        if (!this._initDone) return this;

        // recalculate left margin
        const sYAxis = this._svg.select("g.axes .y");
        sYAxis.call(d3.axisLeft(this._y) as any) // eslint-disable-line
        const wYAxis = (sYAxis.node() as SVGGElement).getBBox().width;
        this._margin.l = Math.ceil(16 + (wYAxis - 6.5));

        // calculations        
        this._layout.w = parseInt(this._svg.style('width'), 10);
        this._layout.h = parseInt(this._svg.style('height'), 10);
        this._layout.wChart = this._layout.w - this._margin.l - this._margin.r;
        this._layout.hChart = this._layout.h - this._margin.t - this._margin.b;

        // axes ranges
        this._x.range([0, this._layout.wChart]);
        this._y.range([0, this._layout.hChart]);

        this._svg.select("g.main").attr("transform", `translate(${this._margin.l}, ${this._margin.t})`);
        this._svg.select("g.axes .x").attr("transform", `translate(0, ${this._layout.hChart + 4})`)
        this._svg.select("g.axes .y").attr("transform", `translate(-${this._margin.axes}, 0)`)

        return this.invalidate();
    }

    private invalidate() {
        // axes
        this._svg.select<SVGGElement>("g.axes .x").call(d3.axisBottom(this._x));
        this._svg.select<SVGGElement>("g.axes .y").call(d3.axisLeft(this._y));

        // bar-graph
        const barPaddingPx = (this._x(this._intervalData.intervalMinutes * 6000) - this._x(0)) * this._layout.paddingBars / 2;
        const barWidth = (this._x(this._intervalData.intervalMinutes * 6000) - this._x(0) - 2 * barPaddingPx) * 8;

        const update = this._svg.select<SVGGElement>("g.bar-graph")
            .selectAll<SVGElement, IntervalDto>('g')
            .data(this._intervalData.intervalBars, d => moment(d.from).valueOf())

        const enter = update.enter().append('g');
        enter.append("rect").attr("class", "barPerformance")
        enter.append("rect").attr("class", "barScrap")
        enter.append("rect").attr("class", "barAvailability")

        const all = enter.merge(update as any);

        all.select("rect.barAvailability")
            .attr("x", d => ((this._x(moment(d.from).valueOf() + (moment(d.until).valueOf() - moment(d.from).valueOf()) / 2))) - barWidth / 2)
            .attr("y", d => this._y(d.aValue + d.qValue + d.pValue))
            .attr("width", barWidth)
            .attr("height", d => this._y(0) - this._y(d.aValue))
            .attr("fill", "#b4c7e7");

        all.select("rect.barPerformance")
            .attr("x", d => ((this._x(moment(d.from).valueOf() + (moment(d.until).valueOf() - moment(d.from).valueOf()) / 2))) - barWidth / 2)
            .attr("y", d => this._y(d.pValue))
            .attr("width", barWidth)
            .attr("height", d => this._y(0) - this._y(d.pValue))
            .attr("fill", "#2f5596");

        all.select("rect.barScrap")
            .attr("x", d => ((this._x(moment(d.from).valueOf() + (moment(d.until).valueOf() - moment(d.from).valueOf()) / 2))) - barWidth / 2)
            .attr("y", d => this._y(d.qValue + d.pValue))
            .attr("width", barWidth)
            .attr("height", d => this._y(0) - this._y(d.qValue))
            .attr("fill", "#f4b184");

        update.exit().remove();

        // nominal-speed
        const updateNominal = this._svg.select<SVGGElement>("g.nominal-speed")
            .selectAll<SVGElement, IntervalDto>('g')
            .data(this._intervalData.intervalBars, d => moment(d?.from).valueOf())

        const enterNominal = updateNominal.enter().append('g');
        enterNominal.append("line").attr("class", "nominalSpeed")

        const allNominal = enterNominal.merge(updateNominal as any);

        allNominal.select("line.nominalSpeed")
            .attr("x1", d => this._x(moment(d.from).valueOf()))
            .attr("x2", d => this._x(moment(d.until).valueOf()))
            .attr("y1", d => this._y(d.nominalProduction))
            .attr("y2", d => this._y(d.nominalProduction))
            .attr("stroke", "green")
            .attr("stroke-width", 3);

        updateNominal.exit().remove();

        // planned-order-end
        if (this._orderData?.expectedOrderEnd != null)
            this._svg.select<SVGGElement>("g.planned-order-end path.planned-order-end")
                .attr("transform", `translate(${this._x(Math.min(moment(this._intervalData.shiftEnd).valueOf(), moment(this._orderData.expectedOrderEnd).valueOf()))}, ${this._y(0) + 22}) rotate(180)`)
                .attr("fill", "red")
                .attr('opacity', '1');
        else
            this._svg.select<SVGGElement>("g.planned-order-end path.planned-order-end")
                .attr('opacity', '0');

        // order-shroud
        if (this._orderData?.orderStart != null)
            this._svg.select<SVGGElement>("g.order-shroud rect.order-shroud")
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', this._x(moment(this._orderData.orderStart).valueOf()))
                .attr('height', this._y(0))
                .attr('fill', 'grey')
                .attr("opacity", "0.6")
        else
            this._svg.select<SVGGElement>("g.order-shroud rect.order-shroud")
                .attr('opacity', '0');

        return this;
    }
    public dispose() {
        this.setIntervalData(null);
        this.setOrderData(null);
        this._resizeObserver.unobserve(this._svg.node()!.parentElement as Element);
        this._resizeObserver.disconnect()
    }
}