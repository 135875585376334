import Api from '@/services/api';
import { OEEDashboardDto } from '../models/OEEDashboardDto';

interface IService {
  getDashboardOEE(machineId: string): Promise<OEEDashboardDto>
}

class Service implements IService {
  public async getDashboardOEE(machineId: string): Promise<OEEDashboardDto> {
    const response = await Api().get<OEEDashboardDto>(`/oee/${machineId}/OeeForOperatorModule`);
    return response.data;
  }

  async getTest(machineId: string) {
    const response = await Api().get(`/oee/${machineId}/interval-dashboard`)
    return response.data;
  }

}

export const OeeService = new Service();
