import api from "@/services/api";
import { notifyService } from "@/store/services";

const url = '/ProductionSpeed';

class ProductionSpeedService {
    async getLastProductionSpeed(machineId: string) {
        try {
            const response = await api().get(`${url}/${machineId}`)
            return response.data
        }
        catch (error) {
            notifyService.error('Could fetch productionspeed')
            return null
        }
    }
}

export const productionSpeedService = new ProductionSpeedService();