import Api from '@/services/api';

import { MultipleStopEditDto, StopDto, StopEditDto } from '../models';

class StopServiceType {
  private url = '/machineStops';

  public async get(
    onlyUnprocessed: boolean,
    machineId: string | null
  ): Promise<StopDto[]> {
    const result = (await Api().get<StopDto[]>(`${this.url}/last-day`, {
      params: {
        onlyUnprocessed,
        machineId
      },
    }));

    return result.data;
  }

  public async editStop(id: number, model: StopEditDto): Promise<void> {
    return await Api().put(`${this.url}/${id}`, model);
  }

  public async editStops(models: MultipleStopEditDto[]) : Promise<void> {
    return await Api().put(`${this.url}`, models);
  }
}

export const StopService = new StopServiceType();
